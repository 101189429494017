button:hover{
    cursor: pointer;
}

.downloads .form-group > label {
    width: 100%;
}

.downloads .form-group > label.label--radio {
    width: inherit;
    margin-right: 1em;
}
