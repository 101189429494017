.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.small {
    font-size: 16;
}

.device-detail-table {
    table-layout: fixed;
    max-width: 750px;

}
.table.device-detail-table td {
    padding-left: 0;
    padding-right: 0;
}

.device-detail-input {
    padding-bottom: 1em;
    padding-top: 0.5em;
}

.nav-link {
    cursor: pointer;
}
