.rw-widget-picker > .rw-select {
    vertical-align: bottom;
}

.dtwp {
    margin-bottom: 1em;
}
.dtwp--expanded {
    position: relative;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: .25rem;
    width: 500px;
    text-align: center;
}

.dtwp--collapsed > * {
    display: inline-block;
}

.dtwp__row {
    margin-bottom: .5em;
}

.dtwp--expanded .dtwp__toggle {
    position: absolute;
    top: .5em;
    right: .5em;
}

.dtwp__windows,
.dtwp__directions {
    display: block;
    list-style: none;
    padding: 0;
}

.dtwp__windows li,
.dtwp__directions li {
    display: inline-block;
    margin: 0 1em;
}

.dtwp__window--active,
.dtwp__direction--active {
    text-decoration: underline;
}

.dtwp__window {
    cursor: pointer;
}

h5.dtwp__inline {
    color: #666;
}

.dtwp__direction--disabled {
    cursor: not-allowed;
}

.dtwp__direction--disabled:hover {
    text-decoration: none;
}

.dtwp__anchor {
    display: flex;
    justify-items: center;
}

.dtwp__anchor > * {
    display: inline-block;
    flex: 1 1 auto;
}

.dtwp__anchor > span {
    padding: 6px 12px;
    flex: 0 0 auto;
}

.dtwp__message {
    margin-top: .5rem;
    color: red;
}

.dtwp__inline {
    display: inline-block;
    margin: 5px;
}

rect.bg {
    opacity: 0.7;
}

.operator-chart-wrapper {
    display: flex;
}

.operator-chart-content,
.operator-chart-padding {
    background-color: transparent;
    flex-direction: row;
}

.operator-chart-padding {
    flex: 0.05 1;
}

.operator-chart-content {
    flex: 0.9 1;
    align-items: center;
    height: 100%;
    padding: 0;
    position: relative;
}

.vpp-operator__window-control {
    display: none;
    position: absolute;
    right: 0;
    justify-content: center;
    color: #666;
    z-index: 1002; /* z-index > 1001 positions the settings modal above the plotly modebar */
}

.vpp-operator__window-control--shown {
    display: flex;
}

.vpp-operator__window-control > * {
    margin-left: 33px; /* half of the width of the line chart */
    background-color: #fff;
}

.vpp-history h2 {
    position: relative;
    z-index: 1002;
    margin-bottom: -15px;
}

.vpp-history div {
    padding-bottom: 5px;
}

.operator-chart__overlay__background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #fff;
    left: 0;
    top: 25px;
    opacity: .5;
}

.operator-chart__overlay__text {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.operator-chart__overlay__text > div {
    min-width: 200px;
    padding: 2em 3em;
    background: #fff;
    margin: 0 auto;
}

.vpp-operator {
    position: relative; /* so that the event banner */
}
.peak-event-banner-wrapper {
    position: relative;
    width: 100%;
    top: 0;
}

.vpp-operator .peak-event-banner {
    margin: -10px auto;
    padding: 10px;
    border: 0;
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 5px;
    max-width: 86%;
}

.operator-widget {
    padding: 1em 0em 1em 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1em;
}

.vpp-state__main {
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
}

@media(min-width: 992px) {
    .vpp-state__main {
        text-align: left;
        justify-content: flex-start;
    }
}

.vpp-state__value {
    color: #007cc3;
    font-size: 12pt;
}

.vpp-state__label {
    color: #666;
    font-size: 12pt;
}

.metrics-container {
    padding-top: 10px;
}

.metrics-card__container {
    padding-bottom: 1em;
}

.metrics-card__card {
    padding: 1em;
    padding-bottom: 0em;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.metrics-card__title {
    color: #666;
    padding-bottom: 10px;
    font-size: 16pt;
    text-transform: uppercase;
}

.metrics-card__content {
    margin-bottom: 0px;
    padding: 0px;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
}

.metrics-card__header {
    color: #666;
    font-size: 11pt;
    background-color: inherit;
    border-width: 0;
    padding-left: 0;
    text-transform: uppercase;
}

.metrics-card__tooltip.tooltip-inner {
    background-color: #666;
    text-align: left;
}

.tooltip span.arrow::before {
    border-right-color: #666;
}

.metrics-card__tooltip-icon {
    color: #666;
    font-size: 12pt;
}

.metrics-card__tooltip {
    float: right;
}

.metrics-card__value {
    color: #007cc3;
    font-size: 16pt;
    margin-bottom: 0.5em;
}

.vpp-state__icon-button {
    background: none;
    border: none;
    padding: 0px 0px 0px 2px;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-menu.vpp-state__dropdown-setpoint {
    padding: 0.5em;
}

.peak-event-banner i.fa {
    margin: 0 10px;
}
.peak-event-banner {
    padding: 10px;
    border: 1px solid #faebcc;
    background-color: #fcf8e3;
    border-radius: 15px;
    margin: 10px;
    text-align: center;
}

.operator-alert {
    padding: 10px;
    border: 1px solid #faebcc;
    background-color: #fcf8e3;
    border-radius: 15px;
    margin-bottom: 0px;
    text-align: center;
}

:root {
    --font-size-xl: 1.75rem;
    --font-size-lg: 1.5rem;
    --font-size: 1rem;
    --font-size-sm: 0.8rem;
}

#app.app-view--static > nav {
  display: none;
}

.nav__logo {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.nav__sidebar {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    width: 250px;
    position: absolute;
    left: -250px;
    display: flex;
    z-index: 10000;
    background-color: #fff;
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s;
}

.nav__sidebar--show {
    -webkit-transform: translateX(250px);
            transform: translateX(250px);
}

.nav__sidebar--hide {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    box-shadow: translate(0 0 0);
}

.nav__container {
    z-index: 10000;
    width: 100%;
}

@media(min-width: 992px) {
    .nav__sidebar {
        position: relative;
        transition: none;
        left: 0px;
    }

    .nav__sidebar--hide {
        -webkit-transform: none;
                transform: none;
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
        background-color: #fff;
    }

    .nav__sidebar--show {
        -webkit-transform: none;
                transform: none;
    }
}

.nav__sub-headers__container {
    padding-left: 20px;
    font-size: var(--font-size-sm);
}

.nav__logout-option {
    cursor: pointer;
    white-space: nowrap;
}

#nav .dropdown-toggle {
    padding-left: 0px;
    padding-bottom: 0px;
    width: 100%;
}

.nav__link-icon {
    width: 20px;
    margin-right: 12px;
    padding-left: 2px;
}

#app .nav__link {
    color: #1477b1;
    opacity: .9;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
}

#app .nav__link.active,
#app .nav__link:hover {
    opacity: 1;
    color: #1477b1;
    border-bottom-color: #1477b1;
}

#app .nav__link.active {
  font-weight: bold;
}

#app .nav__link:hover {
  color: #1477b1;
  border-bottom-color: #1477b1;
}

#app li {
    list-style-type: none;
}

.nav__hamburger {
    z-index: 10000;
    position: absolute;
    right: -36px;
    color: #1477b1;
    width: 20px;
    margin: 20px 0px 0px 0px;
    cursor: pointer;
}

.page-header {
    justify-content: center;
    margin-bottom: 0.75em;
    margin-top: 15px;
}

.page-header__element-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 60px;
}

.page-header h1 {
    text-align: center;
    margin-bottom: 0px;
    margin: 0px 2em 0px;
}

/* breakpoint where container adds 16px padding,
   so decrease icon margin to keep it consistently placed
*/
@media(min-width: 756px) {
    .page-header__hamburger {
        margin-left: 4px;
    }
}

.page-header__subheader {
    display: block;
    font-size: 0.7em;
}

button:hover{
    cursor: pointer;
}

.downloads .form-group > label {
    width: 100%;
}

.downloads .form-group > label.label--radio {
    width: inherit;
    margin-right: 1em;
}

.peak-scheduler form input, .peak-scheduler form label {
    display: block;
}

.peak-scheduler .buttons {
    width: 100%;
    margin: 15px auto;
    text-align: center;
}

.peak-scheduler .buttons button {
    margin: 0 10px;
}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-left: 0;
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

/* TODO: do we really need this? if so... not here... */
/* why isn't this the default :(*/
button {
    cursor: pointer;
}

.peak-scheduler--past-peaks {
    margin-top: 10px;
}

.peak-scheduler__add-peak {
    margin-top: -.25em;
}

.peak-scheduler--upcoming-peaks {
    margin-bottom: 2em;
}

.peak-scheduler__peak-list > .peak-scheduler__peak {
    border-bottom: 1px solid rgb(117, 197, 240);
}

.peak-scheduler__peak-list > .peak-scheduler__peak:last-child {
    border-bottom: none;
}

.peak-scheduler__peak--header {
    font-weight: 600;
    color: #fff;
    background-color: rgb(117, 197, 240);
}

.peak-scheduler__peak,
.peak-scheduler__pagination-container {
    padding: 1em 0;
}

.peak-scheduler__peak--editing {
    padding: 0;
}

.peak-scheduler__peak .form-group {
    margin: -.5rem 0;
}

.peak-scheduler__peak__action {
    border-radius: 4px;
    margin: -.5em .25em 0;
}

.peak-scheduler__peak__error {
    margin-top: 1em;
}

.peak-scheduler__peak__error .alert {
    margin-bottom: 0;
}

.peak-scheduler--upcoming-peaks .col-auto {
    padding-left: 0px;
}

.peak-scheduler--done-peaks h2 {
    margin-left: -15px;
}

.peak-scheduler--notification-input {
    margin-right: 16px;
}

.peak-scheduler--notification-state {
    font-size: 1.15rem;
}

.peak-scheduler--notification-message {
    font-size: .9rem;
}

.row.device-alert__alert-card {
    margin-bottom: 20px;
    margin-left: 0px;
    padding-top: 15px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.device-alerts__single-device-header {
    padding-bottom: 10px;
}

.device-alerts__error-message {
    margin: 16px 0px;
}

.device-alerts__new-alert__row {
    padding-bottom: 10px;
}

.table.device-alert__alert-card__table {
    table-layout: fixed;
}

.device-alert__alert-card__first-cell {
    width: 25%;
}

.error-message {
    padding: 10px;
    border: 1px solid #faebcc;
    background-color: #fcf8e3;
    border-radius: 15px;
    max-width: 630px;
    margin: 0 auto;
}

.center {
    display: block;
    text-align: center;
}

.devices-search__container {
    padding-bottom: 1em;
}

.devices-search__filter-row {
    padding: 0.5em 0em;
}

.devices-search__fixed-range-buttons {
    margin-left: 10px;
}

.devices-search__dropdown {
    max-width: 500px;
    width: 100%;
}

.devices-search__input {
    max-width: 300px;
}

.devices-search__range-button {
    margin-top: 10px;
}

.add-device-container {
    position: absolute;
    top: 15px;
    right: 15px;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.small {
    font-size: 16;
}

.device-detail-table {
    table-layout: fixed;
    max-width: 750px;

}
.table.device-detail-table td {
    padding-left: 0;
    padding-right: 0;
}

.device-detail-input {
    padding-bottom: 1em;
    padding-top: 0.5em;
}

.nav-link {
    cursor: pointer;
}

div.row.mode-series__scale {
    padding-top: 1em;
    padding-right: 1em;
}

.mode-series__scale-colorbar {
    padding-bottom: 5px;
    margin-bottom: 0px;
}

.mode-series__scale-label {
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translateX(20px) rotate(25deg);
            transform: translateX(20px) rotate(25deg);
}

div.col-xs.text-center {
    width: 65px;
    margin-bottom: 30px;
}

#action-container .nav-item .nav-link {
  color: #1477b1;
  opacity: .9;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 2px;
  padding: .5em 1em;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  justify-content: center;
}

#action-container .nav-item .nav-link.active,
#action-container .nav-item .nav-link:hover {
  opacity: 1;
  background-color: #fff;
  color: #1477b1;
  border-bottom-color: #1477b1;
}

#action-container .nav-item .nav-link.active {
  font-weight: bold;
}

#action-container .nav-item .nav-link:hover {
  color: #1477b1;
  border-bottom-color: #1477b1;
}

#action-container .action-menu {
    padding: 0px 0px 16px;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
    font-size: var(--font-size);
    height: 100%;
    overflow: hidden;
}
.btn-primary {
    background-color: rgb(53, 123, 165);;
    border: 0;
}
.btn-primary:hover {
    background-color: rgb(48, 112, 152);;
}

a {
    color: #115D91;
}
.image {
    position: relative;
    width: 100%; /* for IE 6 */
}
.centered {
    text-align: center;
}
h1 {
    font-size: var(--font-size-xl);
    color: #666;
    font-weight: 400;
    margin-bottom: 1rem;
}

h2 {
    font-size: var(--font-size-lg);
    color: #666;
}

h2.text-center {
    position: relative;
    margin-bottom: 2rem;
}

#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#app main {
    flex: 1 1;
    display: flex;
    justify-content: center;
}

.app__scrollable-section {
    overflow-y: auto;
    height: 100vh;
}

.nav__column {
    padding: 0px;
}

.app__main-scroll-area {
    flex: 1 1;
    padding: 0 2rem;
}

@media(min-width: 992px) {
    .nav__column {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.app__logo {
    color: '#ccc';
    text-align: 'right';
    padding-right: 32;
    margin-top: 125;
}

.app__container-with-footer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.app__footer-element {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
}

@media (min-width: 3000px) {
    .faster-than-realtime {
        max-width: 3500px;
        margin: 2rem auto;
        font-size: 2rem;
    }


    .faster-than-realtime h1 {
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    .faster-than-realtime h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
}

body.route-login {
    background-color: rgb(53, 123, 165);
    height: 100vh;
    width: 100%; /* for IE 6 */
}

body.route-login .navbar {
  display: none;
}

.login__container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login__hero {
  display: block;
  padding: 2rem 4rem 4rem;
  background-color: white;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  color: rgb(53, 123, 165);
}

.login__hero h1 {
  font-weight: 700;
  color: rgb(53, 123, 165);
}

.login__hero .login__by-block {
  margin-bottom: 2rem;
}

.login__hero h3 {
  display: inline-block;
  color: rgb(53, 123, 165);
  vertical-align: middle;
  margin-right: 1rem;
}

.login__hero img {
  display: inline-block;
  height: 8rem;
}

@media (min-width: 756px) {
  .login__hero {
    padding: 3rem 4rem 4rem;
    width: 50%;
    max-width: 600px;
  }

  .login__hero img {
    /* width: 80%; */
  }
}


