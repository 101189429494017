.vpp-operator {
    position: relative; /* so that the event banner */
}
.peak-event-banner-wrapper {
    position: relative;
    width: 100%;
    top: 0;
}

.vpp-operator .peak-event-banner {
    margin: -10px auto;
    padding: 10px;
    border: 0;
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 5px;
    max-width: 86%;
}

.operator-widget {
    padding: 1em 0em 1em 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1em;
}
