.operator-chart-wrapper {
    display: flex;
}

.operator-chart-content,
.operator-chart-padding {
    background-color: transparent;
    flex-direction: row;
}

.operator-chart-padding {
    flex: 0.05;
}

.operator-chart-content {
    flex: 0.9;
    align-items: center;
    height: 100%;
    padding: 0;
    position: relative;
}

.vpp-operator__window-control {
    display: none;
    position: absolute;
    right: 0;
    justify-content: center;
    color: #666;
    z-index: 1002; /* z-index > 1001 positions the settings modal above the plotly modebar */
}

.vpp-operator__window-control--shown {
    display: flex;
}

.vpp-operator__window-control > * {
    margin-left: 33px; /* half of the width of the line chart */
    background-color: #fff;
}

.vpp-history h2 {
    position: relative;
    z-index: 1002;
    margin-bottom: -15px;
}

.vpp-history div {
    padding-bottom: 5px;
}

.operator-chart__overlay__background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #fff;
    left: 0;
    top: 25px;
    opacity: .5;
}

.operator-chart__overlay__text {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.operator-chart__overlay__text > div {
    min-width: 200px;
    padding: 2em 3em;
    background: #fff;
    margin: 0 auto;
}
