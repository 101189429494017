body.route-login {
    background-color: rgb(53, 123, 165);
    height: 100vh;
    width: 100%; /* for IE 6 */
}

body.route-login .navbar {
  display: none;
}

.login__container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login__hero {
  display: block;
  padding: 2rem 4rem 4rem;
  background-color: white;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  color: rgb(53, 123, 165);
}

.login__hero h1 {
  font-weight: 700;
  color: rgb(53, 123, 165);
}

.login__hero .login__by-block {
  margin-bottom: 2rem;
}

.login__hero h3 {
  display: inline-block;
  color: rgb(53, 123, 165);
  vertical-align: middle;
  margin-right: 1rem;
}

.login__hero img {
  display: inline-block;
  height: 8rem;
}

@media (min-width: 756px) {
  .login__hero {
    padding: 3rem 4rem 4rem;
    width: 50%;
    max-width: 600px;
  }

  .login__hero img {
    /* width: 80%; */
  }
}

