.page-header {
    justify-content: center;
    margin-bottom: 0.75em;
    margin-top: 15px;
}

.page-header__element-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 60px;
}

.page-header h1 {
    text-align: center;
    margin-bottom: 0px;
    margin: 0px 2em 0px;
}

/* breakpoint where container adds 16px padding,
   so decrease icon margin to keep it consistently placed
*/
@media(min-width: 756px) {
    .page-header__hamburger {
        margin-left: 4px;
    }
}

.page-header__subheader {
    display: block;
    font-size: 0.7em;
}
