@import '../styles/variables.css';

#app.app-view--static > nav {
  display: none;
}

.nav__logo {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.nav__sidebar {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    width: 250px;
    position: absolute;
    left: -250px;
    display: flex;
    z-index: 10000;
    background-color: #fff;
    transition: transform .25s;
}

.nav__sidebar--show {
    transform: translateX(250px);
}

.nav__sidebar--hide {
    transform: translateX(0px);
    box-shadow: translate(0 0 0);
}

.nav__container {
    z-index: 10000;
    width: 100%;
}

@media(min-width: 992px) {
    .nav__sidebar {
        position: relative;
        transition: none;
        left: 0px;
    }

    .nav__sidebar--hide {
        transform: none;
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
        background-color: #fff;
    }

    .nav__sidebar--show {
        transform: none;
    }
}

.nav__sub-headers__container {
    padding-left: 20px;
    font-size: var(--font-size-sm);
}

.nav__logout-option {
    cursor: pointer;
    white-space: nowrap;
}

#nav .dropdown-toggle {
    padding-left: 0px;
    padding-bottom: 0px;
    width: 100%;
}

.nav__link-icon {
    width: 20px;
    margin-right: 12px;
    padding-left: 2px;
}

#app .nav__link {
    color: #1477b1;
    opacity: .9;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
}

#app .nav__link.active,
#app .nav__link:hover {
    opacity: 1;
    color: #1477b1;
    border-bottom-color: #1477b1;
}

#app .nav__link.active {
  font-weight: bold;
}

#app .nav__link:hover {
  color: #1477b1;
  border-bottom-color: #1477b1;
}

#app li {
    list-style-type: none;
}

.nav__hamburger {
    z-index: 10000;
    position: absolute;
    right: -36px;
    color: #1477b1;
    width: 20px;
    margin: 20px 0px 0px 0px;
    cursor: pointer;
}
