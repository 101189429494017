@import './styles/variables.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
    font-size: var(--font-size);
    height: 100%;
    overflow: hidden;
}
.btn-primary {
    background-color: rgb(53, 123, 165);;
    border: 0;
}
.btn-primary:hover {
    background-color: rgb(48, 112, 152);;
}

a {
    color: #115D91;
}
.image {
    position: relative;
    width: 100%; /* for IE 6 */
}
.centered {
    text-align: center;
}
h1 {
    font-size: var(--font-size-xl);
    color: #666;
    font-weight: 400;
    margin-bottom: 1rem;
}

h2 {
    font-size: var(--font-size-lg);
    color: #666;
}

h2.text-center {
    position: relative;
    margin-bottom: 2rem;
}

#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#app main {
    flex: 1;
    display: flex;
    justify-content: center;
}

.app__scrollable-section {
    overflow-y: auto;
    height: 100vh;
}

.nav__column {
    padding: 0px;
}

.app__main-scroll-area {
    flex: 1;
    padding: 0 2rem;
}

@media(min-width: 992px) {
    .nav__column {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.app__logo {
    color: '#ccc';
    text-align: 'right';
    padding-right: 32;
    margin-top: 125;
}

.app__container-with-footer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.app__footer-element {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
}

@media (min-width: 3000px) {
    .faster-than-realtime {
        max-width: 3500px;
        margin: 2rem auto;
        font-size: 2rem;
    }


    .faster-than-realtime h1 {
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    .faster-than-realtime h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
}
