/* TODO: do we really need this? if so... not here... */
/* why isn't this the default :(*/
button {
    cursor: pointer;
}

.peak-scheduler--past-peaks {
    margin-top: 10px;
}

.peak-scheduler__add-peak {
    margin-top: -.25em;
}

.peak-scheduler--upcoming-peaks {
    margin-bottom: 2em;
}

.peak-scheduler__peak-list > .peak-scheduler__peak {
    border-bottom: 1px solid rgb(117, 197, 240);
}

.peak-scheduler__peak-list > .peak-scheduler__peak:last-child {
    border-bottom: none;
}

.peak-scheduler__peak--header {
    font-weight: 600;
    color: #fff;
    background-color: rgb(117, 197, 240);
}

.peak-scheduler__peak,
.peak-scheduler__pagination-container {
    padding: 1em 0;
}

.peak-scheduler__peak--editing {
    padding: 0;
}

.peak-scheduler__peak .form-group {
    margin: -.5rem 0;
}

.peak-scheduler__peak__action {
    border-radius: 4px;
    margin: -.5em .25em 0;
}

.peak-scheduler__peak__error {
    margin-top: 1em;
}

.peak-scheduler__peak__error .alert {
    margin-bottom: 0;
}

.peak-scheduler--upcoming-peaks .col-auto {
    padding-left: 0px;
}

.peak-scheduler--done-peaks h2 {
    margin-left: -15px;
}

.peak-scheduler--notification-input {
    margin-right: 16px;
}

.peak-scheduler--notification-state {
    font-size: 1.15rem;
}

.peak-scheduler--notification-message {
    font-size: .9rem;
}
