div.row.mode-series__scale {
    padding-top: 1em;
    padding-right: 1em;
}

.mode-series__scale-colorbar {
    padding-bottom: 5px;
    margin-bottom: 0px;
}

.mode-series__scale-label {
    transform-origin: top left;
    transform: translateX(20px) rotate(25deg);
}

div.col-xs.text-center {
    width: 65px;
    margin-bottom: 30px;
}
