.error-message {
    padding: 10px;
    border: 1px solid #faebcc;
    background-color: #fcf8e3;
    border-radius: 15px;
    max-width: 630px;
    margin: 0 auto;
}

.center {
    display: block;
    text-align: center;
}
