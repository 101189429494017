.peak-event-banner i.fa {
    margin: 0 10px;
}
.peak-event-banner {
    padding: 10px;
    border: 1px solid #faebcc;
    background-color: #fcf8e3;
    border-radius: 15px;
    margin: 10px;
    text-align: center;
}
