.row.device-alert__alert-card {
    margin-bottom: 20px;
    margin-left: 0px;
    padding-top: 15px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.device-alerts__single-device-header {
    padding-bottom: 10px;
}

.device-alerts__error-message {
    margin: 16px 0px;
}

.device-alerts__new-alert__row {
    padding-bottom: 10px;
}

.table.device-alert__alert-card__table {
    table-layout: fixed;
}

.device-alert__alert-card__first-cell {
    width: 25%;
}
