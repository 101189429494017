.vpp-state__main {
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
}

@media(min-width: 992px) {
    .vpp-state__main {
        text-align: left;
        justify-content: flex-start;
    }
}

.vpp-state__value {
    color: #007cc3;
    font-size: 12pt;
}

.vpp-state__label {
    color: #666;
    font-size: 12pt;
}

.metrics-container {
    padding-top: 10px;
}

.metrics-card__container {
    padding-bottom: 1em;
}

.metrics-card__card {
    padding: 1em;
    padding-bottom: 0em;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.metrics-card__title {
    color: #666;
    padding-bottom: 10px;
    font-size: 16pt;
    text-transform: uppercase;
}

.metrics-card__content {
    margin-bottom: 0px;
    padding: 0px;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
}

.metrics-card__header {
    color: #666;
    font-size: 11pt;
    background-color: inherit;
    border-width: 0;
    padding-left: 0;
    text-transform: uppercase;
}

.metrics-card__tooltip.tooltip-inner {
    background-color: #666;
    text-align: left;
}

.tooltip span.arrow::before {
    border-right-color: #666;
}

.metrics-card__tooltip-icon {
    color: #666;
    font-size: 12pt;
}

.metrics-card__tooltip {
    float: right;
}

.metrics-card__value {
    color: #007cc3;
    font-size: 16pt;
    margin-bottom: 0.5em;
}

.vpp-state__icon-button {
    background: none;
    border: none;
    padding: 0px 0px 0px 2px;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-menu.vpp-state__dropdown-setpoint {
    padding: 0.5em;
}
