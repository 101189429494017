.peak-scheduler form input, .peak-scheduler form label {
    display: block;
}

.peak-scheduler .buttons {
    width: 100%;
    margin: 15px auto;
    text-align: center;
}

.peak-scheduler .buttons button {
    margin: 0 10px;
}