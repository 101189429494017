.dtwp {
    margin-bottom: 1em;
}
.dtwp--expanded {
    position: relative;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: .25rem;
    width: 500px;
    text-align: center;
}

.dtwp--collapsed > * {
    display: inline-block;
}

.dtwp__row {
    margin-bottom: .5em;
}

.dtwp--expanded .dtwp__toggle {
    position: absolute;
    top: .5em;
    right: .5em;
}

.dtwp__windows,
.dtwp__directions {
    display: block;
    list-style: none;
    padding: 0;
}

.dtwp__windows li,
.dtwp__directions li {
    display: inline-block;
    margin: 0 1em;
}

.dtwp__window--active,
.dtwp__direction--active {
    text-decoration: underline;
}

.dtwp__window {
    cursor: pointer;
}

h5.dtwp__inline {
    color: #666;
}

.dtwp__direction--disabled {
    cursor: not-allowed;
}

.dtwp__direction--disabled:hover {
    text-decoration: none;
}

.dtwp__anchor {
    display: flex;
    justify-items: center;
}

.dtwp__anchor > * {
    display: inline-block;
    flex: 1 1 auto;
}

.dtwp__anchor > span {
    padding: 6px 12px;
    flex: 0 0 auto;
}

.dtwp__message {
    margin-top: .5rem;
    color: red;
}

.dtwp__inline {
    display: inline-block;
    margin: 5px;
}
