#action-container .nav-item .nav-link {
  color: #1477b1;
  opacity: .9;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 2px;
  padding: .5em 1em;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  justify-content: center;
}

#action-container .nav-item .nav-link.active,
#action-container .nav-item .nav-link:hover {
  opacity: 1;
  background-color: #fff;
  color: #1477b1;
  border-bottom-color: #1477b1;
}

#action-container .nav-item .nav-link.active {
  font-weight: bold;
}

#action-container .nav-item .nav-link:hover {
  color: #1477b1;
  border-bottom-color: #1477b1;
}

#action-container .action-menu {
    padding: 0px 0px 16px;
}
