.devices-search__container {
    padding-bottom: 1em;
}

.devices-search__filter-row {
    padding: 0.5em 0em;
}

.devices-search__fixed-range-buttons {
    margin-left: 10px;
}

.devices-search__dropdown {
    max-width: 500px;
    width: 100%;
}

.devices-search__input {
    max-width: 300px;
}

.devices-search__range-button {
    margin-top: 10px;
}

.add-device-container {
    position: absolute;
    top: 15px;
    right: 15px;
}
